import React, { useRef, useEffect, useState } from 'react'; 
import './recognition.scss'; 
import Logo from '../../assets/logo.png';

const test_data = [
    {
        title: "James F.B. Peyton Fund",
        description: ""
    },
    {
        title: "Rita Isom and Family",
        description: ""
    },
    {
        title: "Quincy Walmart", 
        description: ""
    },
    {
        title: "Hurricane Walmart", 
        description: ""
    },
    {
        title: "B.R.E.A.M Neighborhood Shop", 
        description: ""
    }
]; 

const CardItem = ({title, description}) => {

    const calculateFontSize = (text) => {
        if (text.length < 20) return '1.8rem';
        if (text.length < 30) return '1.6rem';
        if (text.length < 40) return '1.4rem';
        return '1.2rem';
    };

    return (
        <div className="r-card">
            <div className="rec-card-header"
                style={{fontSize: calculateFontSize(title)}}
            >
                {title}
            </div>
            <div className="rec-card-img">
                <img src={Logo}/>
            </div>
            <div className="rec-card-body">
                {description}
            </div>
        </div>
    )
}

const RecognitionCarousel = () => {
    const carouselRef = useRef(null); 
    const [animate, setAnimate] = useState(true); 

    // useEffect Hook to determine if carousel should be scrolling 
    // determines if children are overflowing the parent container

    const checkOverflow = () => {
        if (!carouselRef.current) return;
        const carousel = carouselRef.current;
        const carouselItems = carousel.children;
        const carouselWidth = carousel.offsetWidth;
        const carouselItemsWidth = Array.from(carouselItems).reduce((acc, item) => acc + item.offsetWidth, 0);
        console.log("Carousel Width: ", carouselWidth);
        console.log("Carousel Items Width: ", carouselItemsWidth);
        
        if(animate){
            setAnimate((carouselItemsWidth/2) > carouselWidth);
        }
        else{
            setAnimate(carouselItemsWidth > carouselWidth);
        }
      };
    
      // Use `useEffect` to check for overflow when the component mounts or updates
      useEffect(() => {
        checkOverflow(); // Check initially
    
        // Add a resize event listener
        const handleResize = () => checkOverflow();
        window.addEventListener('resize', handleResize);
    
        // Observe changes in carousel content using MutationObserver
        const observer = new MutationObserver(checkOverflow);
        if (carouselRef.current) {
          observer.observe(carouselRef.current, { childList: true, subtree: true });
        }
    
        // Cleanup event listeners and observers on unmount
        return () => {
          window.removeEventListener('resize', handleResize);
          observer.disconnect();
        };
      }, []);

    return(
        <div className={`r-carousel ${animate ? "r-animate" : ""}`} ref={carouselRef}>
            <div className="r-group">
                {
                    test_data.map((val, i) => (
                        <CardItem
                            title={val.title}
                            description={val.description}
                        />
                    ))
                }
            </div>
            {
                animate ? (
                    <div className="r-group">
                        {
                            test_data.map((val, i) => (
                                <CardItem
                                    title={val.title}
                                    description={val.description}
                                />
                            ))
                        }
                    </div>
                ) : null 
            }
            
        </div>
    )
}

export default RecognitionCarousel; 